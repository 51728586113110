import { Job } from "@ethossoftworks/job"
import { BlocCoordinator } from "@lib/bloc/BlocCoordinator"
import { Router, withRouter } from "@lib/router/router"
import { ExternalRoutes, Routes } from "@lib/Routes"
import { isFloat, isValidDate, safeParseFloat, safeParseInt } from "@lib/TypeUtil"
import { HourMeterDriftTolerance, OdometerDriftTolerance } from "@model/assets/MeterDriftTolerance"
import { PriorAssetUsage } from "@model/assets/PriorAssetUsage"
import { Attachment } from "@model/Attachment"
import { BillingCode } from "@model/company/BillingCode"
import { Contact } from "@model/contacts/Contact"
import { ServiceFormType } from "@model/serviceRequests/ServiceForm"
import { ServiceCode, ServiceRequestType, ServiceSubType } from "@model/serviceRequests/ServiceRequest"
import { Site } from "@model/site/Site"
import { TagChange, tagChangeFromTagType, tagChangeToTagType, TagType } from "@model/Tag"
import { CompletableTaskValue, InspectionTaskValue, TaskValue } from "@model/Task"
import { Urgency } from "@model/Urgency"
import { PermissionObject, PermissionType } from "@model/user/User"
import { CBACode } from "@model/workOrders/CbaCode"
import { WorkOrderStatus } from "@model/workOrders/WorkOrder"
import { WorkOrderPrintOption } from "@model/workOrders/WorkOrderPrintOption"
import { WorkRequest, WorkRequestStatus } from "@model/workRequests/WorkRequest"
import { CompanyBloc, CompanyState } from "@state/company/CompanyBloc"
import { StatusBloc } from "@state/StatusBloc"
import { UserBloc, UserState } from "@state/user/UserBloc"
import {
    WorkOrderEffect,
    WorkOrderScreenBloc,
    WorkOrderScreenState,
    WorkOrderWorkRequestForm,
} from "@state/workOrders/WorkOrderScreenBloc"
import { ServiceRequestCreationBloc } from "@state/workRequests/ServiceRequestCreationBloc"

type Dependencies = [WorkOrderScreenState, CompanyState, UserState]

type StateSelection = WorkOrderScreenState & {
    mechanics: Contact[]
    cbaCodes: CBACode[]
    contacts: Contact[]
    billingCodes: BillingCode[]
    serviceCodes: ServiceCode[]
    isLoading: boolean
    hasDataFetchError: boolean
    assignedToContact: Contact | null
    serviceSubTypes: Record<ServiceRequestType, ServiceSubType>
    workPerformedByContacts: Contact[]
    workPerformedByContactsSelected: Contact[] | null
    sites: Site[]
    priorAssetUsage: PriorAssetUsage | null
    latestAssetUsage: PriorAssetUsage | null
    openedFormDate: Date
    isSubscribedInAllWorkRequests: boolean
    hasBeenShownSyncDoneAfterServiceDateModal: boolean
    companyLogoUrl: string | null
}

export class WorkOrderScreenViewModel extends BlocCoordinator<Dependencies, StateSelection> {
    private router: Router
    isStatusFlagReasonRequired(form: WorkOrderWorkRequestForm) {
        if (form.status === WorkRequestStatus.WorkNotPerformed) return true
        else
            return (
                form.status === WorkRequestStatus.Done &&
                form.tasks?.some((task) =>
                    [
                        InspectionTaskValue.NotPerformed,
                        InspectionTaskValue.Fail,
                        CompletableTaskValue.NotPerformed,
                    ].some((x) => x === task.value)
                )
            )
    }
    constructor(
        public workOrderBloc: WorkOrderScreenBloc,
        private companyBloc: CompanyBloc,
        private statusBloc: StatusBloc,
        public serviceRequestCreationBloc: ServiceRequestCreationBloc,
        private userBloc: UserBloc
    ) {
        super([workOrderBloc, companyBloc, userBloc])
        this.router = withRouter((router) => router)
    }

    protected transform = ([workOrderState, companyState, userState]: Dependencies): StateSelection => ({
        ...workOrderState,
        billingCodes: [],
        serviceCodes: Object.values(companyState.serviceCodes),
        contacts: companyState.contacts,
        mechanics: companyState.settings.mechanics,
        cbaCodes: companyState.cbaCodes,
        isLoading: workOrderState.effectStatus[WorkOrderEffect.Fetch].isBusy(),
        hasDataFetchError: workOrderState.effectStatus[WorkOrderEffect.Fetch].isError(),
        assignedToContact: companyState.contacts.find((it) => workOrderState.form.assignedTo === it.id) ?? null,
        serviceSubTypes: companyState.settings.serviceSubTypes,
        workPerformedByContactsSelected:
            workOrderState.form.workPerformedById != null
                ? companyState.contacts.filter((it) => workOrderState.form.workPerformedById!.includes(it.id)) ?? null
                : null,
        workPerformedByContacts: companyState.settings.mechanics,
        sites: companyState.allSites,
        priorAssetUsage: workOrderState.assetUsage,
        latestAssetUsage: workOrderState.latestAssetUsage,
        openedFormDate: new Date(),
        isSubscribedInAllWorkRequests:
            workOrderState.workRequests.filter(
                (item) => item.notifyContacts.filter((id) => id == userState.user.personID).length == 0
            ).length == 0,
        companyLogoUrl: companyState.companyLogoUrl,
    })


    loadWorkRequestHistory = async (workRequestId: number) => {
        this.workOrderBloc.fetchHistoryData(workRequestId)
    }

    loadWorkOrderHistory = async (workOrderId: number) => {
        this.workOrderBloc.fetchWorkOrderHistoryData(workOrderId)
    }

    viewWorkOrdersClicked = withRouter((router) => () => router.navigate(Routes.WorkOrders()))

    onMounted = (workOrderId: string) => this.workOrderBloc.fetchData(safeParseInt(workOrderId))
    cancelClicked = () => this.workOrderBloc.resetForm()

    saveClicked = async () => {
        const messageId = this.statusBloc.enqueueInfoMessage("Updating work order...")
        const outcome = await this.workOrderBloc.saveForm()

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating work order")
        }
        this.statusBloc.hideInfoMessage(messageId)
    }

    ClosedWorkOrderEditModeClicked = async () => this.workOrderBloc.ClosedWorkOrderEditModeChanged(true)

    ClosedWorkOrderCancelClicked = async () => this.workOrderBloc.ClosedWorkOrderEditModeChanged(false)

    areMetersOutRange = (): boolean => {
        return (
            this.isOdometerOutsideExpectedRange(
                this.state.form.completedOdometer,
                this.state.priorAssetUsage?.assetReading.odometer,
                this.state.asset?.displayOdometer
            ) ||
            this.isHourMeterOutsideExpectedRange(
                this.state.form.completedHourMeter,
                this.state.priorAssetUsage?.assetReading.hourMeter,
                this.state.asset?.displayHourMeter
            )
        )
    }

    getNotSubscribedWorkRequest = (personId: number) =>
        this.state.workRequests.filter((item) => item.notifyContacts.filter((id) => id == personId).length == 0)

    subscribeUser = async () => {
        let personId = this.userBloc.state.user.personID
        if (personId) {
            let workRequestNotSubscribed = this.getNotSubscribedWorkRequest(personId)

            workRequestNotSubscribed.forEach(async (workRequest) => {
                const messageId = this.statusBloc.enqueueInfoMessage("Updating service request...")
                this.workOrderBloc.hideWorkRequestDetails()

                if (!personId) return

                const outcome = await this.workOrderBloc.subscribeUser(workRequest.id, personId)
                if (!Job.isCancelled(outcome) && outcome.isError()) {
                    this.statusBloc.enqueueErrorMessage("Error updating service request")
                }

                this.statusBloc.hideInfoMessage(messageId)
            })
        }
    }

    handleSaveClicked = async () => {
        var canSave = true

        if (this.state.form.status == WorkOrderStatus.Closed) canSave = await this.handleStateChangedToClose()

        if (canSave) this.saveClicked()
    }

    handleStateChangedToClose = async () => {
        let endOfAssetLabel = this.state.asset?.label?.substring(this.state.asset?.label?.length - 5)

        const outcome = await this.workOrderBloc.checkHaveBeenSyncBeforeServiceDate()
        if (outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error on checkHaveBeenSyncBeforeServiceDate")
            return false
        }

        let hasBeenSyncAfterServiceDate = outcome.value.value.length > 0

        if (!hasBeenSyncAfterServiceDate) {
            if (!this.areMetersOutRange() && this.state.workOrder?.status !== WorkOrderStatus.Closed)
                this.performMeterSync()

            if (
                this.areMetersOutRange() &&
                !this.state.syncMeterShown &&
                endOfAssetLabel?.includes("+") && //Paired
                !endOfAssetLabel?.includes("@") //Not OEM
            ) {
                if (this.state.workOrder?.status !== WorkOrderStatus.Closed) this.showMeterSync()
                return false
            }
        } else {
            if (!this.state.hasBeenShownSyncDoneAfterServiceDateModal) {
                if (this.state.workOrder?.status !== WorkOrderStatus.Closed) this.showSyncDoneAfterServiceDateModal()

                this.meterSyncChanged(false)
                this.syncDoneAfterServiceDateModalShown()
            }
        }

        if (
            !this.state.tagUpdateShown &&
            this.state.asset?.tag &&
            this.state.asset?.tag?.type != TagType.None &&
            (this.userBloc.state.user.hasAccess(PermissionObject.RedYellowTag, PermissionType.Add) ||
                this.userBloc.state.user.hasAccess(PermissionObject.RedYellowTag, PermissionType.Delete))
        ) {
            this.showTagUpdate()
            return false
        }

        if (this.getUser().hasAccess(PermissionObject.ServiceQuote, PermissionType.View)) {
            var result = await this.handleServioceQuoteScheduleAssociationProcess()
            if (!result) return false
        }

        if (!endOfAssetLabel?.includes("+") || endOfAssetLabel?.toLocaleLowerCase().includes("+r")) {
            //Unpaired or RFID
            if (
                this.state.asset &&
                this.state.form.workCompletedDate &&
                this.state.form.completedOdometer &&
                this.state.form.completedHourMeter
            )
                this.workOrderBloc.createManualEntry(
                    this.state.asset?.id,
                    this.state.form.workCompletedDate,
                    Number(this.state.form.completedOdometer.replace(/,/g, "")),
                    Number(this.state.form.completedHourMeter.replace(/,/g, "")),
                    this.state.form.siteId
                )
        }

        return true
    }

    handleServioceQuoteScheduleAssociationProcess = async () => {
        const outcomeScheduleAssociation = await this.workOrderBloc.checkHaveScheduleAssociation()
        if (outcomeScheduleAssociation.isError()) {
            this.statusBloc.enqueueErrorMessage("Error on checking Schedule Association")
            return false
        }

        let assetScheduleIds = outcomeScheduleAssociation.value

        if (assetScheduleIds.length > 0 && !this.state.serviceRequestHasBeenAdjusted) {
            const outcomeWorkRequestBySchedule = await this.workOrderBloc.fetchWorkRequestBySchedule(assetScheduleIds)

            if (outcomeWorkRequestBySchedule.isError()) {
                this.statusBloc.enqueueErrorMessage("Error on checking Schedule Association")
                return false
            }
            var assetSchedules = outcomeWorkRequestBySchedule.value

            this.updateScheduleWorkRequests(assetSchedules)
            //update the list of work request to be adjusted
            this.showAdjustServiceRequestModal()

            return false
        }

        return true
    }

    adjustServiceRequests = async (
        serviceRequestsToManualAdjust: WorkRequest[],
        serviceRequestsToAdvanceStep: WorkRequest[]
    ) => {
        const messageId = this.statusBloc.enqueueInfoMessage("Adjusting service requests...")

        const outcomeAdvanceToNextScheduleStep = await this.workOrderBloc.advanceServiceRequestsToNextStep(
            serviceRequestsToAdvanceStep
        )

        const outcomeUpdateServiceRequest = await this.workOrderBloc.saveScheduleRequestForm(
            serviceRequestsToManualAdjust
        )

        if (
            !Job.isCancelled(outcomeAdvanceToNextScheduleStep) &&
            outcomeAdvanceToNextScheduleStep.isError() &&
            !Job.isCancelled(outcomeUpdateServiceRequest) &&
            outcomeUpdateServiceRequest.isError()
        ) {
            this.statusBloc.enqueueErrorMessage("Error Adjusting service requests")
        }

        if (outcomeAdvanceToNextScheduleStep.isOk() && outcomeUpdateServiceRequest.isOk()) {
            this.serviceRequestScheduleHasBeenAdjusted()
            this.hideAdjustServiceRequestModal()
        }

        this.statusBloc.hideInfoMessage(messageId)
    }

    performMeterSync = async () => this.meterSyncChanged(true)

    tagStatusChanged = (tagChange: TagChange) => this.workOrderBloc.tagStatusChanged(tagChangeToTagType(tagChange))

    tagReasonChanged = (tagReason: string) => this.workOrderBloc.tagReasonChanged(tagReason)

    tagUpdateShownChanged = (tagUpdateShown: boolean) => this.workOrderBloc.tagUpdateBeenShown(tagUpdateShown)

    showTagUpdate = () => this.workOrderBloc.showTagUpdate(true)
    hideTagUpdate = () => this.workOrderBloc.showTagUpdate(false)

    meterSyncChanged = (value: boolean) => this.workOrderBloc.meterSyncUpdate(value)

    meterSyncShownChanged = (meterSyncShown: boolean) => this.workOrderBloc.syncMetersBeenShown(meterSyncShown)

    showMeterSync = () => this.workOrderBloc.showSyncMeters(true)
    hideMeterSync = () => this.workOrderBloc.showSyncMeters(false)

    showSyncDoneAfterServiceDateModal = () => this.workOrderBloc.showSyncDoneAfterServiceDateModal(true)
    hideSyncDoneAfterServiceDateModal = () => this.workOrderBloc.showSyncDoneAfterServiceDateModal(false)

    onCloseUpdateTagModal = () => {
        this.tagUpdateShownChanged(true)
        this.hideTagUpdate()
        this.handleSaveClicked()
    }

    onUpdateTagModalConfirmClicked = () => {
        this.onCloseUpdateTagModal()
    }

    onUpdateTagModalCancelClicked = () => {
        this.hideTagUpdate()
    }

    onUpdateTagModalNoTagChangeClicked = () => {
        this.tagReasonChanged(this.state?.asset?.tag?.reason ?? "")
        this.tagStatusChanged(tagChangeFromTagType(this.state?.asset?.tag?.type ?? TagType.None))
        this.onCloseUpdateTagModal()
    }

    onCloseMeterSyncModal = () => {
        this.meterSyncShownChanged(true)
        this.hideMeterSync()
        this.handleSaveClicked()
    }

    onCloseMeterSyncDoneAfterServiceDateModal = () => {
        this.hideSyncDoneAfterServiceDateModal()
    }

    onMeterSyncModalPerformSyncClicked = () => {
        this.performMeterSync()
        this.onCloseMeterSyncModal()
    }
    onMeterSyncModalCloseWithoutSyncClicked = () => {
        this.addMeterSyncRequiredFlag()
        this.onCloseMeterSyncModal()
    }

    onMeterSyncModalCloseClicked = () => {
        this.hideMeterSync()
    }

    addMeterSyncRequiredFlag = async () => {
        if (this.state.asset?.id == null) return

        this.meterSyncChanged(false)
    }

    showWorkRequestDetails = (workRequestId: number) => this.workOrderBloc.showWorkRequestDetails(workRequestId)
    workRequestDetailsCancelClicked = (requestId: number) => {
        if (this.state.workRequestDetailsModal) {
            this.workOrderBloc.clearWorkRequestAttachmentsOnCancel(this.state.workRequestDetailsModal)
        }
        this.workOrderBloc.hideWorkRequestDetails()
        this.workOrderBloc.resetRequestForm(requestId)
    }
    workRequestDetailsSaveClicked = async (requestId: number) => {
        const messageId = this.statusBloc.enqueueInfoMessage("Updating repair request...")
        this.workOrderBloc.hideWorkRequestDetails()

        const outcome = await this.workOrderBloc.saveRequestForm(requestId, this.userBloc.state.user)
        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating repair request")
        }

        this.statusBloc.hideInfoMessage(messageId)
        this.refreshWorkRequestHistory(requestId)
    }

    refreshWorkRequestHistory = async (requestId: number) => {
        const outcome = await this.workOrderBloc.fetchAndRefreshWorkRequestHistory(requestId)
        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating repair request history")
        }
    }

    getUser = () => this.userBloc.state.user

    statusChanged = (status: WorkOrderStatus) => {
        this.workOrderBloc.statusChanged(status)

        if (
            status == WorkOrderStatus.Open ||
            (this.state.form.openedDate == null &&
                (status == WorkOrderStatus.InProgress ||
                    status == WorkOrderStatus.WorkCompleted ||
                    status == WorkOrderStatus.Closed))
        ) {
            this.workOrderBloc.openedDateChanged(new Date())
        }

        if (status == WorkOrderStatus.WorkCompleted) {
            this.workOrderBloc.fetchAssetUsageData(
                this.state.workOrder ? this.state.workOrder.assetId : 0,
                this.state.workOrder?.workCompletedDate ?? this.state.openedFormDate
            )
        }
    }

    assignedToChanged = (contactId: number) => this.workOrderBloc.assignedToChanged(contactId)

    workPerformedByChanged = (contactIds: number[]) => this.workOrderBloc.workPerformedByChanged(contactIds)

    siteIdChanged = (siteId: number) => this.workOrderBloc.siteIdChanged(siteId)

    specialInstructionsChanged = (specialInstructions: string) =>
        this.workOrderBloc.specialInstructionsChanged(specialInstructions)

    cbaCodeChanged = (cbaCodeId: number) => this.workOrderBloc.cbaCodeChanged(cbaCodeId)

    travelTimeChanged = (travelTime: string) => this.workOrderBloc.travelTimeChanged(travelTime)

    travelDistanceChanged = (travelDistance: string) => this.workOrderBloc.travelDistanceChanged(travelDistance)

    nightWorkChanged = (nightWork: boolean) => this.workOrderBloc.nightWorkChanged(nightWork)

    urgencyChanged = (urgency: Urgency) => this.workOrderBloc.urgencyChanged(urgency)

    dueDateChanged = (dueDate: Date) => this.workOrderBloc.dueDateChanged(dueDate)

    dueOdometerChanged = (dueOdometer: string) => this.workOrderBloc.dueOdometerChanged(dueOdometer)

    workCompletedDateChanged = (workCompletedDate: Date) => {
        this.workOrderBloc.workCompletedDateChanged(workCompletedDate)
        if (isValidDate(workCompletedDate)) {
            this.workOrderBloc.fetchAssetUsageData(
                this.state.workOrder ? this.state.workOrder.assetId : 0,
                workCompletedDate
            )
        }
    }

    completedOdometerChanged = (completedOdometer: string) =>
        this.workOrderBloc.completedOdometerChanged(completedOdometer)

    completedHourMeterChanged = (completedHourMeter: string) =>
        this.workOrderBloc.completedHourMeterChanged(completedHourMeter)

    odometerLifetimeOdometerEditClicked = () => {
        window.open(ExternalRoutes.SmartHubAssetSync(this.state.asset?.id ?? 0), "_blank")
    }

    isHourMeterOutsideExpectedRange = (
        completedHourMeter: any,
        expectedHourMeter: any,
        displayHourMeter: any
    ): boolean => {
        if (displayHourMeter != null && !displayHourMeter) {
            return false
        }

        return this.isOutsideMeterDriftTolerance(
            safeParseFloat(completedHourMeter),
            safeParseFloat(expectedHourMeter),
            null,
            HourMeterDriftTolerance.max
        )
    }

    isOdometerOutsideExpectedRange = (completedOdometer: any, expectedOdometer: any, displayOdometer: any): boolean => {
        if (displayOdometer != null && !displayOdometer) {
            return false
        }
        return this.isOutsideMeterDriftTolerance(
            safeParseInt(completedOdometer),
            safeParseInt(expectedOdometer),
            null,
            OdometerDriftTolerance.max
        )
    }

    isOutsideMeterDriftTolerance = (
        x: number,
        y: number,
        minDriftTolerance: number | null,
        maxDriftTolerance: number | null
    ): boolean => {
        var minVariance = y + (minDriftTolerance ?? 0)
        var maxVariance = y + (maxDriftTolerance ?? 0)
        var minNegativeVariance = y - (minDriftTolerance ?? 0)
        var maxNegativeVariance = y - (maxDriftTolerance ?? 0)

        var isBelowMin = false
        var isAboveMax = false

        if (minDriftTolerance != null) isBelowMin = x < minVariance || x > minNegativeVariance

        if (maxDriftTolerance != null) isAboveMax = x < maxNegativeVariance || x > maxVariance

        return isBelowMin || isAboveMax
    }

    isServiceDateTimeOtsideExpectedRange = (serviceDateTime: Date): boolean =>
        serviceDateTime > this.state.openedFormDate

    dueHourMeterChanged = (dueHourMeter: string) => this.workOrderBloc.dueHourMeterChanged(dueHourMeter)

    hourMeterLifetimeHourMeterEditClicked = () => {
        window.open(ExternalRoutes.SmartHubAssetSync(this.state.asset?.id ?? 0), "_blank")
    }

    isRequestFormValid = (requestId: number): boolean => {
        const form = this.state.workRequestForms[requestId]
        if (!form) return false

        if (this.isStatusFlagReasonRequired(form))
            if (!form.statusFlagReason || form.statusFlagReason === "") return false

        return form.tag === null || form.tag.reason.trim() !== ""
    }

    isFormValid = (): boolean => {
        const form = this.state.form
        if (!form) return false

        if (form.status === WorkOrderStatus.WorkCompleted) return this.workOrderChangeToWorkCompletedValidation()

        if (form.status === WorkOrderStatus.Closed) return this.workOrderChangeToClosedValidation()

        return true
    }

    workOrderChangeToWorkCompletedValidation = (): boolean =>
        !(
            this.workCompletedFieldsAreInvalid(this.state.openedFormDate) ||
            this.workRequestAreInToDoStatus(this.state.workRequests, this.state.workRequestForms)
        )

    workOrderChangeToClosedValidation = (): boolean =>
        this.userBloc.state.user.hasAccess(PermissionObject.CloseWorkOrder, PermissionType.Execute) &&
        this.state.workOrder?.status == WorkOrderStatus.WorkCompleted &&
        this.workRequestAreInDoneStatus(this.state.workRequests, this.state.workRequestForms) &&
        (!this.state.form.closedWorkOrderEditMode
            ? this.state.form.completedHourMeter != null && this.state.form.completedOdometer != null
            : true) &&
        this.state.form.workCompletedDate != null &&
        this.state.form.workCompletedDate < this.state.openedFormDate

    workCompletedFieldsAreInvalid = (openedFormDate: Date): boolean =>
        this.state.form.workCompletedDate == null ||
        this.state.form.workCompletedDate > openedFormDate || //checks that WorkCompletedDate is not in the future
        ((this.state.asset?.displayHourMeter ?? this.state.assetUsage?.assetReading != null) &&
            (this.state.asset?.displayHourMeter ??
                safeParseFloat(this.state.assetUsage?.assetReading.hourMeter) != 0) &&
            this.state.form.completedHourMeter == "" &&
            !isFloat(this.state.form.completedHourMeter)) || // checks Hour Meter field
        ((this.state.asset?.displayOdometer ?? this.state.assetUsage?.assetReading != null) &&
            (this.state.asset?.displayOdometer ?? safeParseFloat(this.state.assetUsage?.assetReading.odometer) != 0) &&
            this.state.form.completedOdometer == "" &&
            !isFloat(this.state.form.completedOdometer)) // checks Odometer field

    workRequestAreInToDoStatus = (
        workRequests: WorkRequest[],
        workRequestsForms: Record<number, WorkOrderWorkRequestForm>
    ): boolean => {
        return this.anyWorkRequestByStatus(workRequests, workRequestsForms, WorkRequestStatus.ToDo)
    }

    workRequestAreInDoneStatus = (
        workRequests: WorkRequest[],
        workRequestsForms: Record<number, WorkOrderWorkRequestForm>
    ): boolean => {
        return (
            this.countWorkRequestByStatus(workRequests, workRequestsForms, WorkRequestStatus.Done) ==
            workRequests.length
        )
    }

    anyWorkRequestByStatus = (
        workRequests: WorkRequest[],
        workRequestsForms: Record<number, WorkOrderWorkRequestForm>,
        status: WorkRequestStatus
    ): boolean => {
        return this.countWorkRequestByStatus(workRequests, workRequestsForms, status) > 0
    }

    countWorkRequestByStatus = (
        workRequests: WorkRequest[],
        workRequestsForms: Record<number, WorkOrderWorkRequestForm>,
        status: WorkRequestStatus
    ): number => {
        return workRequests.filter((it) => workRequestsForms[it.id].status == status).length
    }

    requestStatusChanged = (requestId: number, status: WorkRequestStatus) => {
        const workRequestForm = this.state.workRequestForms[requestId]
        const workRequest = this.state.workRequests.find((it) => it.id === safeParseInt(requestId))
        if (!workRequest) return

        this.workOrderBloc.requestStatusChanged(requestId, status)
    }
    requestStatusChangedFromWRList = (requestId: number, status: WorkRequestStatus) => {
        let previousStatus = this.state.workRequestForms[requestId].status

        this.requestStatusChanged(requestId, status) //Changes the form

        const workRequestForm = this.state.workRequestForms[requestId]
        const workRequest = this.state.workRequests.find((it) => it.id === safeParseInt(requestId))
        if (!workRequest) return

        if (this.isStatusFlagReasonRequired(workRequestForm)) {
            this.workNotPerformedReasonModalShow(workRequest, previousStatus, status)
        } else {
            //Changes made directly from list are directly saved.
            this.saveRequestForm(requestId)
        }
    }

    requestStepChanged = (requestId: number, value: string) => {
        const workRequest = this.state.workRequests.find((wr) => wr.id === requestId)
        const step = workRequest?.schedule?.steps.find((it) => it.id === safeParseInt(value))
        if (!step) return
        this.workOrderBloc.requestStepChanged(requestId, step)
    }

    requestBillingCodeChanged = (requestId: number, value: string) => {
        const billingCode = this.state.billingCodes.find((it) => it.id === safeParseInt(value))
        if (!billingCode) return
        this.workOrderBloc.requestBillingCodeChanged(requestId, billingCode)
    }

    requestManualAdjustmentChanged = (requestId: number, value: boolean, shouldUpdate: boolean = false) => {
        this.workOrderBloc.requestManualAdjustmentChanged(requestId, value)
        if (shouldUpdate) this.saveRequestForm(requestId)
    }

    closeAdjustServiceRequestModal = () => {
        this.workOrderBloc.adjustServiceRequestModalVisibleChanged(false)
        this.workOrderBloc.serviceRequestHasBeenAdjustedChanged(true)
    }
    showAdjustServiceRequestModal = () => this.workOrderBloc.adjustServiceRequestModalVisibleChanged(true)
    hideAdjustServiceRequestModal = () => this.workOrderBloc.adjustServiceRequestModalVisibleChanged(false)

    serviceRequestScheduleHasBeenAdjusted = () => this.workOrderBloc.serviceRequestHasBeenAdjustedChanged(true)

    updateScheduleWorkRequests = (workRequests: WorkRequest[]) =>
        this.workOrderBloc.updateScheduleWorkRequests(workRequests)

    requestServiceCodeChanged = (requestId: number, value: string, shouldUpdate: boolean = false) => {
        const serviceCode = this.state.serviceCodes.find((it) => it.id === safeParseInt(value))
        this.workOrderBloc.requestServiceCodeChanged(requestId, serviceCode ?? null)
        if (shouldUpdate) this.saveRequestForm(requestId)
    }

    dismissWorkOrderClicked = () => this.workOrderBloc.workOrderDismissChanged("", [])
    dismissWorkOrderCancelClicked = () => this.workOrderBloc.workOrderDismissCancelled()
    dismissWorkOrderReasonChanged = (reason: string) =>
        this.workOrderBloc.workOrderDismissChanged(reason, this.state.workOrderDismissal?.workRequestsToDelete ?? [])
    dismissWorkOrderWorkRequestsToDismissChanged = (workRequestId: number, isChecked: boolean) => {
        const filtered = (this.state.workOrderDismissal?.workRequestsToDelete ?? []).filter(
            (it) => it !== workRequestId
        )
        this.workOrderBloc.workOrderDismissChanged(this.state.workOrderDismissal?.reason ?? "", [
            ...filtered,
            ...(isChecked ? [workRequestId] : []),
        ])
    }
    dismissWorkOrderConfirmClicked = withRouter((router) => async (reason: string, workRequestsToDelete: number[]) => {
        this.workOrderBloc.workOrderDismissCancelled()
        const infoMessage = this.statusBloc.enqueueInfoMessage("Deleting work order...")
        const outcome = await this.workOrderBloc.dismissWorkOrder(reason, workRequestsToDelete)

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error deleting work order")
            this.statusBloc.hideInfoMessage(infoMessage)
            return
        }

        this.statusBloc.hideInfoMessage(infoMessage)
        router.navigate(Routes.WorkOrders())
    })

    removeWorkRequestClicked = (workRequest: WorkRequest) => this.workOrderBloc.workRequestToRemoveChanged(workRequest)
    removeWorkRequestCancelClicked = () => this.workOrderBloc.workRequestToRemoveChanged(null)
    removeWorkRequestConfirmClicked = async (workRequest: WorkRequest) => {
        this.workOrderBloc.workRequestToRemoveChanged(null)
        const infoMessage = this.statusBloc.enqueueInfoMessage("Removing service request...")
        const outcome = await this.workOrderBloc.removeWorkRequest(workRequest)

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error removing service request")
        }
        this.statusBloc.hideInfoMessage(infoMessage)
    }

    addFollowUpWorkRequestClicked = (workRequestId: number) => {
        var followUpWorkRequest = this.state.workRequests.find((it) => it.id === workRequestId)
        //Set here the service sub type
        if (followUpWorkRequest) followUpWorkRequest.serviceType = ServiceRequestType.FollowUp

        this.workOrderBloc.renderCreateServiceRequest(true)
        this.serviceRequestCreationBloc.showModal(ServiceFormType.Request, followUpWorkRequest, this.state.workOrder!)
    }

    navigateToFollowUpWorkRequest = (assetId: string, workRequestId: string, openInNewTab: boolean = false) => {
        this.router.navigate(Routes.WorkRequest(assetId, workRequestId), false, openInNewTab)
    }

    dismissWorkRequestClicked = (workRequest: WorkRequest) =>
        this.workOrderBloc.workRequestToDismissChanged(workRequest, {})
    dismissWorkRequestReasonChanged = (workRequest: WorkRequest, reason: string) =>
        this.workOrderBloc.workRequestToDismissChanged(workRequest, { reason: reason })
    dismissWorkRequestTagChanged = (workRequest: WorkRequest, change: TagChange) =>
        this.workOrderBloc.workRequestToDismissChanged(workRequest, { tagChange: change })
    dismissWorkRequestTagReasonChanged = (workRequest: WorkRequest, tagReason: string) =>
        this.workOrderBloc.workRequestToDismissChanged(workRequest, { tagReason: tagReason })
    dismissWorkRequestCancelClicked = () => this.workOrderBloc.workRequestToDismissChanged(null, {})
    dismissWorkRequestConfirmClicked = async () => {
        const data = this.state.workRequestToDismiss
        if (data === null) return

        this.workOrderBloc.workRequestToDismissChanged(null, {})
        const infoMessage = this.statusBloc.enqueueInfoMessage("Dismissing service request...")
        const outcome = await this.workOrderBloc.dismissWorkRequest(
            data.request,
            data.reason,
            this.userBloc.state.user.id,
            data.tagChange,
            data.tagReason
        )

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error dismissing service request")
        }
        this.statusBloc.hideInfoMessage(infoMessage)
    }

    workNotPerformedReasonModalShow = (
        workRequest: WorkRequest,
        previousStatus: WorkRequestStatus,
        newStatus: WorkRequestStatus
    ) => this.workOrderBloc.workRequestWithWorkNotPerformedSet(workRequest, previousStatus, newStatus)
    workNotPerformedReasonModalHide = () => this.workOrderBloc.workRequestWithWorkNotPerformedSet(null, null, null)

    workNotPerformedReasonChanged = (workRequest: WorkRequest, reason: string) =>
        this.workOrderBloc.workNotPerformedReasonChanged(workRequest, { reason })
    workNotPerformedReasonModalCancelClicked = () => {
        if (this.state.workRequestWorkNotPerformed?.previousStatus)
            this.requestStatusChanged(
                this.state.workRequestWorkNotPerformed.request.id,
                this.state.workRequestWorkNotPerformed.previousStatus
            )

        this.workNotPerformedReasonModalHide()
    }
    workNotPerformedReasonModalSaveClicked = async () => {
        const data = this.state.workRequestWorkNotPerformed
        if (data === null) return

        const form = this.state.workRequestForms[data.request.id]

        this.workOrderBloc.requestStatusChanged(data.request.id, form.status)
        this.workOrderBloc.requestStatusFlagReasonChanged(data.request.id, data.reason)
        this.saveRequestForm(data.request.id)
        this.workNotPerformedReasonModalHide()
    }

    requestUrgencyChanged = (requestId: number, urgency: Urgency) =>
        this.workOrderBloc.requestUrgencyChanged(requestId, urgency)

    requestDueDateChanged = (requestId: number, value: Date) =>
        this.workOrderBloc.requestDueDateChanged(requestId, value)

    requestDueHourMeterChanged = (requestId: number, value: string) =>
        this.workOrderBloc.requestDueHourMeterChanged(requestId, value)

    requestDueOdometerChanged = (requestId: number, value: string) =>
        this.workOrderBloc.requestDueOdometerChanged(requestId, value)

    requestServiceTypeChanged = (requestId: number, value: ServiceRequestType) =>
        this.workOrderBloc.requestServiceTypeChanged(requestId, value)

    requestEstimatedPartsCostChanged = (requestId: number, value: string) =>
        this.workOrderBloc.requestEstimatedPartsCostChanged(requestId, value)

    requestEstimatedLaborHoursChanged = (requestId: number, value: string) =>
        this.workOrderBloc.requestEstimatedLaborHoursChanged(requestId, value)

    requestActualLaborHoursChanged = (requestId: number, value: string) =>
        this.workOrderBloc.requestActualLaborHoursChanged(requestId, value)

    requestActualLaborCostChanged = (requestId: number, value: string) =>
        this.workOrderBloc.requestActualLaborCostChanged(requestId, value)

    requestActualPartsCostChanged = (requestId: number, value: string) =>
        this.workOrderBloc.requestActualPartsCostChanged(requestId, value)

    requestSpecialInstructionsChanged = (requestId: number, value: string) =>
        this.workOrderBloc.requestSpecialInstructionsChanged(requestId, value)

    requestNotesChanged(requestId: number, value: string): void {
        this.workOrderBloc.requestNotesChanged(requestId, value)
    }
    requestWorkToBePerformedChanged = (requestId: number, value: string) =>
        this.workOrderBloc.requestWorkToBePerformedChanged(requestId, value)

    requestTasksChanged = (requestId: number, taskId: number, value: TaskValue, measurement?: string) =>
        this.workOrderBloc.requestTaskChanged(requestId, taskId, value, measurement)

    requestTagReasonChanged = (requestId: number, value: string) =>
        this.workOrderBloc.requestTagReasonChanged(requestId, value)

    requestTagTypeChanged = (requestId: number, value: TagType | null) =>
        this.workOrderBloc.requestTagTypeChanged(requestId, value)

    requestSelectAllTasksToggled = (requestId: number) => this.workOrderBloc.requestSelectAllTasksToggled(requestId)

    requestNotifyContactsChanged = (requestId: number, value: Contact[]) =>
        this.workOrderBloc.requestNotifyContactsChanged(requestId, value)

    workRequestAttachmentsChanged = async (files: File[], formData: FormData) => {
        if (this.state.workRequestDetailsModal) {
            const message = this.statusBloc.enqueueInfoMessage("Attaching File...")
            const outcome = await this.workOrderBloc.workRequestAttachmentsChanged(
                this.state.workRequestDetailsModal,
                files,
                this.userBloc.state.user.id,
                this.userBloc.state.user.companyId
            )
            this.statusBloc.hideInfoMessage(message)
        }
    }

    workRequestAttachmentDeleted = (attachment: Attachment) => {
        if (this.state.workRequestDetailsModal)
            this.workOrderBloc.workRequestAttachmentsDelete(
                this.state.workRequestDetailsModal,
                attachment.name,
                this.userBloc.state.user.id,
                this.userBloc.state.user.companyId
            )
    }
    rotateImage = (attachment: Attachment, angle: number) => {
        if (this.state.workRequestDetailsModal)
            this.workOrderBloc.rotateImage(
                attachment.name,
                angle,
                this.userBloc.state.user.id,
                this.userBloc.state.user.companyId,
                this.state.workRequestDetailsModal
            )
    }

    attachmentError = (errors: string[]) => this.statusBloc.enqueueErrorMessage(errors.join("<br>"))

    toggleShowTaskDescriptions = () => this.workOrderBloc.toggleShowTaskDescriptions()

    saveRequestForm = async (requestId: number) => {
        if (!this.workOrderBloc.isWorkRequestFormChanged(requestId)) return
        const messageId = this.statusBloc.enqueueInfoMessage("Updating service request...")
        const outcome = await this.workOrderBloc.saveRequestForm(requestId, this.userBloc.state.user)

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error updating service request")
            this.workOrderBloc.resetRequestForm(requestId)
        }

        this.statusBloc.hideInfoMessage(messageId)
    }

    createServiceButtonClicked(formType: ServiceFormType) {
        this.workOrderBloc.renderCreateServiceRequest(true)
        this.serviceRequestCreationBloc.showModal(formType)
    }

    partListViewClicked = () => {
        this.workOrderBloc.showPartsList(true)
        if (this.state.asset?.id) this.workOrderBloc.loadParts(this.state.asset?.id)
    }

    partListViewCloseButtonClicked = () => {
        this.workOrderBloc.showPartsList(false)
    }

    meterSyncInfoClicked = () => {
        this.workOrderBloc.showMeterSyncInfo(true)
    }
    meterSyncInfoCloseButtonClicked = () => {
        this.workOrderBloc.showMeterSyncInfo(false)
    }

    workOrderToPrintChanged = (workOrderId: number | null) => this.workOrderBloc.workOrderToPrintChanged(workOrderId)

    workOrderPrintOptionSelected = async (printOption: WorkOrderPrintOption) =>
        this.workOrderBloc.printWorkOrder(
            printOption,
            this.companyBloc.state.contacts,
            Object.values(this.companyBloc.state.serviceCodes),
            this.companyBloc.state.allSites
        )

    hasWorkOrderEditPermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.MaintenanceWorkOrder, PermissionType.Edit)

    hasWorkOrderDeletePermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.MaintenanceWorkOrder, PermissionType.Delete)

    hasWorkRequestViewPermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.WorkRequest, PermissionType.View)

    hasWorkRequestAddPermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.WorkRequest, PermissionType.Add)

    hasWorkRequestDeletePermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.WorkRequest, PermissionType.Delete)

    hasRedYellowTagAddPermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.RedYellowTag, PermissionType.Add)

    hasRedYellowTagDeletePermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.RedYellowTag, PermissionType.Delete)

    hasWorkOrderCloseExecutePermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.CloseWorkOrder, PermissionType.Execute)

    hasRollbackPermission = () =>
        this.userBloc.state.user.hasAccess(PermissionObject.RollbackWorkOrder, PermissionType.Execute)

    showRollbackModal = () => this.workOrderBloc.showRollbackModal()

    hideRollbackModal = () => this.workOrderBloc.hideRollbackModal()

    rollbackWorkOrderConfirmClicked = async () => {
        const messageId = this.statusBloc.enqueueInfoMessage("Performing work order rollback...")
        const outcome = await this.workOrderBloc.workOrderRollbackModalConfirmed()

        if (!Job.isCancelled(outcome) && outcome.isError()) {
            this.statusBloc.enqueueErrorMessage("Error when trying to rollback work order..")
        }

        setTimeout(() => {
            this.statusBloc.hideInfoMessage(messageId)
        }, 5000)
    }

    rollbacksWorkOrderCancelClicked(): void {
        this.hideRollbackModal()
    }

    syncDoneAfterServiceDateModalShown = () => this.workOrderBloc.hasBeenShownSyncDoneAfterServiceDateModal(true)

    requestNotifyContactChanged = (requestId: number, contactIds: number[]) => {
        const workRequest = this.state.workRequests.find((it) => it.id === safeParseInt(requestId))
        if (!workRequest) return
        this.workOrderBloc.requestNotifyContactChanged(requestId, contactIds)
    }
}
