import { BuildInfo } from "@lib/BuildInfo"
import { route } from "@lib/router/route"

const createTitle = (pageName: string) => `Gauge Telematics - ${pageName}`

export const Routes = {
    Root: route({ path: "/" }),
    Dashboard: route({ path: "/dashboard", title: () => createTitle("Dashboard") }),
    DashboardBasicCompany: route({ path: "/dashboard?isBasicCompany", title: () => createTitle("Dashboard") }),
    WorkRequests: route({
        path: "/work-requests",
        title: () => createTitle("Service Requests"),
        data: (filter?: string) => ({ query: { ...(filter !== undefined ? { filter: filter } : {}) } }),
    }),
    AssetWorkRequests: route({
        path: "/work-requests/asset/:assetId",
        title: () => createTitle("Service Requests"),
        data: (assetId: string) => ({ params: { assetId } }),
        meta: { transitionKey: "assetWorkRequests" },
    }),
    UserWorkRequests: route({
        path: "/work-requests/user/:userId",
        title: () => createTitle("Service Requests"),
        data: (userId: string) => ({ params: { userId } }),
        meta: { transitionKey: "assetWorkRequests" },
    }),
    UserWorkRequest: route({
        path: "/work-requests/user/:assetId/:workRequestId",
        title: () => createTitle("Service Request"),
        data: (assetId: string, workRequestId: string) => ({ params: { assetId, workRequestId } }),
        meta: { transitionKey: "assetWorkRequests" },
    }),
    WorkRequest: route({
        path: "/work-requests/asset/:assetId/:workRequestId",
        title: () => createTitle("Service Request"),
        data: (assetId: string, workRequestId: string) => ({ params: { assetId, workRequestId } }),
        meta: { transitionKey: "assetWorkRequests" },
    }),
    WorkOrders: route({
        path: "/work-orders",
        title: () => createTitle("Work Orders"),
        data: (filter?: string) => ({ query: { ...(filter !== undefined ? { filter: filter } : {}) } }),
    }),
    WorkOrdersBasciCompany: route({
        path: "/work-orders?isBasicCompany",
        title: () => createTitle("Work Orders"),
    }),
    WorkOrder: route({
        path: "/work-orders/:workOrderId",
        title: () => createTitle("Service Request"),
        data: (workOrderId: string) => ({ params: { workOrderId } }),
    }),
    GlobalSettings: route({ path: "/admin/global-settings", title: () => createTitle("Global Settings") }),
    ServiceRequest: route({
        path: "/service-request",
        title: () => createTitle("Service Request"),
    }),
    ServiceRecord: route({
        path: "/service-record",
        title: () => createTitle("Service Record"),
    }),
    CreateServiceRequest: route({
        path: "/work-requests/create-service-request/:assetId",
        title: () => createTitle("Create Repair Request"),
        data: (assetId: string) => ({ params: { assetId } }),
    }),
    CreateServiceRecord: route({
        path: "/work-requests/create-service-record/:assetId",
        title: () => createTitle("Create Service Record"),
        data: (assetId: string) => ({ params: { assetId } }),
    }),
    ServiceQuotes: route({
        path: "/service-quotes",
        title: () => createTitle("Service Quotes"),
        data: (filter?: string) => ({ query: { ...(filter !== undefined ? { filter: filter } : {}) } }),
    }),
    ServiceQuote: route({
        path: "/service-quotes/:serviceQuoteId",
        title: () => createTitle("Service Quote"),
        data: (filter?: string) => ({ query: { ...(filter !== undefined ? { filter: filter } : {}) } }),
    }),
}

export const apiHost = process.env.ApiHost as string

export const smartHubHost = BuildInfo.isBasicCompany
    ? (process.env.SmartHubLabsHost as string)
    : (process.env.SmartHubHost as string)

export const tokenEndpoint = BuildInfo.isBasicCompany
    ? (process.env.SmartHubLabsTokenUrl as string)
    : (process.env.SmartHubTokenUrl as string)

export const ExternalRoutes = {
    SmartHubClients: () => `${smartHubHost}/Clients`,
    SmartHubAssets: () => `${smartHubHost}/Assets`,
    SmartHubUsers: () => `${smartHubHost}/Account`,
    SmartHubServiceSchedule: () => `${smartHubHost}/WorkOrder/Index`,
    SmartHubMap: () => `${smartHubHost}/MapsSites`,
    SmartHubReports: () => `${smartHubHost}/Report`,
    SmartHubElog: () => `${smartHubHost}/ELog/Index`,
    SmartHubDashboard: () => `${smartHubHost}/Home`,
    SmartHubSites: () => `${smartHubHost}/Sites`,
    SmartHubTasks: () => `${smartHubHost}/Task`,
    SmartHubCompany: () => `${smartHubHost}/Company/My`,
    SmartHubNotifications: () => `${smartHubHost}/Notification`,
    SmartHubWinterOps: () => `${smartHubHost}/WinterOps/Dashboard`,

    SmartHubAsset: (assetId: number) => `${smartHubHost}/Assets/View/${assetId}`,
    SmartHubAssetSync: (assetId: number) => `${smartHubHost}/Assets/Sync/${assetId}`,
    SmartHubSite: (siteId: string) => `${smartHubHost}/Sites/View/${siteId}`,

    SmartHubMyInfo: () => `${smartHubHost}/Account/MyInfo`,
    SmartHubLogin: (redirectPath: string) =>
        `${smartHubHost}/Account/LogOn?OverrideToHost=maintenance&ReturnUrl=${encodeURIComponent(redirectPath)}`,
    SmartHubLogout: () =>
        `${smartHubHost}/Account/LogOff?OverrideToHost=maintenance&ReturnUrl=${encodeURIComponent("/")}`,

    SmartHubReleaseNotes: () => `${smartHubHost}/Home/ReleaseNotes`,
    SmartHubContact: () => `mailto:support@gaugesmart.com`,
    SmartHubSupport: () => `https://gaugesmart.atlassian.net/servicedesk/customer/portal/3`,
    SmartHubServiceSchedules: () => `${smartHubHost}/ServiceSchedule/Index`,
    SmartHubServiceSteps: () => `${smartHubHost}/ServiceType/Index`,
    SmartHubServiceTasks: () => `${smartHubHost}/ServiceTask/Index`,
    SmartHubServiceCodes: () => `${smartHubHost}/ServiceCode/Index`,
    SmartHubServiceNotifications: () => `${smartHubHost}/ServiceNotifications/Index`,
    SmartHubServiceQuoteVendor: () => `${smartHubHost}/Person/Index?servicequotevendor=true`,
    SmartHubCBACodes: () => `${smartHubHost}/CBACode/Index`,
    SmartHubUnImpersonate: () => `${smartHubHost}/Company/UnImpersonate`,
}
